<script setup lang="ts">
/* Computed variables */

/**
 * The current partner.
 */
const partner: ComputedRef<Partner> = computed(
  (): Partner => useCommonStore().partner
);
</script>

<template>
  <main class="w-screen h-screen flex-row">
    <div class="bg-white w-full h-full p-8 flex justify-center items-center xl:max-w-2xl">
      <div class="flex flex-col justify-center items-start gap-y-8 max-w-[25rem] w-full">
        <img class="h-12 min-[576px]:hidden" :alt="partner.name + ' logo'"
             :src="partner.square_logo_url" />

        <img class="hidden min-[576px]:inline h-12" :alt="partner.name + ' logo'"
             :src="partner.logo_url" />

        <slot />
      </div>
    </div>

    <div class="hidden xl:flex justify-center items-center w-full">
      <svg width="574" height="492" viewBox="0 0 574 492" fill="none" class="fill-none"
           xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8183_2082)">
          <path d="M0.689453 490.812H573.312" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M407.984 446.084L407.364 457.197C407.364 457.197 412.036 459.29 419.587 459.056C427.138 458.822 430.858 457.197 430.858 457.197L429.825 446.084H407.984Z"
            fill="white" />
          <path
            d="M407.364 457.197C407.364 457.197 406.414 467.043 399.91 472.29C393.406 477.536 379.929 479.74 375.589 486.047L431.892 483.169L430.445 457.197C430.445 457.197 417.299 461.645 407.364 457.197Z"
            fill="#3936DB" />
          <path d="M376.14 485.964L374.004 490.509H432.305L431.892 483.155L376.14 485.964Z"
                fill="#E3E3F2" />
          <path
            d="M429.824 446.084L432.305 490.509H374.003C374.003 490.509 373.742 483.843 384.462 479.533C395.183 475.223 408.645 474.3 407.998 446.084"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M431.892 483.155L375.589 486.033" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M430.445 457.197C430.445 457.197 416.018 461.383 407.364 457.197"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M394.37 326.098L405.862 446.084H430.955L429.701 330.312L429.728 323.193L394.37 326.098Z"
            fill="white" />
          <path
            d="M394.37 326.098L405.862 446.084H430.969C430.969 446.084 431.809 368.87 429.825 333.204C429.646 330.064 429.632 326.704 429.742 323.206"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M394.37 326.098L429.728 323.193C429.728 323.193 428.805 305.924 435.075 279.388C441.344 252.851 446.594 213.081 446.594 213.081L383.884 216.606L394.37 326.098Z"
            fill="white" />
          <path d="M429.729 323.193C430.858 286.92 445.837 233.641 446.595 213.067" stroke="#1F1F23"
                stroke-linecap="round" stroke-linejoin="round" />
          <path d="M383.884 216.606L394.37 326.098" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M426.793 446.084L426.173 457.197C426.173 457.197 430.844 459.29 438.395 459.056C445.946 458.822 449.667 457.197 449.667 457.197L448.633 446.084H426.793Z"
            fill="white" />
          <path
            d="M426.173 457.197C426.173 457.197 425.222 467.043 418.718 472.29C412.214 477.536 398.738 479.74 394.397 486.047L450.701 483.169L449.254 457.197C449.254 457.197 436.108 461.645 426.173 457.197Z"
            fill="#3936DB" />
          <path d="M394.948 485.964L392.799 490.509H451.114L450.7 483.155L394.948 485.964Z"
                fill="#E3E3F2" />
          <path
            d="M448.634 446.084L451.114 490.509H392.813C392.813 490.509 392.551 483.843 403.272 479.533C413.992 475.223 427.455 474.3 426.807 446.084"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M450.701 483.155L394.397 486.033" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M449.254 457.197C449.254 457.197 434.826 461.383 426.173 457.197"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M413.179 326.098L424.657 446.084H449.763L448.509 330.312L448.537 323.193L413.179 326.098Z"
            fill="white" />
          <path
            d="M413.179 326.098L424.671 446.084H449.777C449.777 446.084 450.618 368.87 448.633 333.204C448.454 330.064 448.44 326.704 448.551 323.206"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M413.179 326.098L448.537 323.193C448.537 323.193 447.614 305.924 453.883 279.388C460.153 252.851 465.403 213.081 465.403 213.081L402.692 216.606L413.179 326.098Z"
            fill="white" />
          <path d="M448.537 323.193C449.667 286.92 464.645 233.641 465.403 213.067" stroke="#1F1F23"
                stroke-linecap="round" stroke-linejoin="round" />
          <path d="M402.692 216.606L413.179 326.098" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M298.699 129.643L293.738 121.023L285.87 111.397C285.87 111.397 283.045 114.468 284.203 116.602C285.36 118.737 285.305 119.522 285.512 120.155C285.719 120.788 286.669 126.765 286.669 126.765C286.669 126.765 277.644 124.135 276.39 123.419C275.136 122.703 264.691 114.44 264.691 114.44L261.99 117.139C261.99 117.139 269.183 125.126 269.114 125.044C269.045 124.961 255.39 119.163 255.39 119.163L254.15 122.813L266.537 131.64L254.522 128.72C254.522 128.72 253.667 132.246 253.695 132.177C253.723 132.108 265.49 138.635 265.49 138.635L253.819 138.718L254.508 142.147C254.508 142.147 282.095 149.267 284.534 149.225C286.973 149.184 290.541 148.785 292.581 148.84C294.62 148.895 301.179 149.212 301.179 149.212L302.254 147.256C305.175 141.954 305.561 135.633 303.301 130.015L298.713 129.643H298.699Z"
            fill="#B7B6FF" />
          <path
            d="M303.287 130.015L298.134 129.602C298.134 129.602 295.819 123.749 293.476 120.94C291.519 118.599 287.51 113.311 286.214 111.59C286.008 111.314 285.594 111.328 285.415 111.631C284.643 112.898 283.1 115.886 284.382 117.731C286.035 120.1 287.165 126.93 287.165 126.93L278.953 124.383C277.299 123.873 275.742 123.047 274.378 121.973L265.063 114.633C264.608 114.275 263.96 114.344 263.588 114.771L262.238 116.341C261.88 116.767 261.893 117.387 262.293 117.786L270.299 125.842L256.065 118.971C255.596 118.75 255.045 118.971 254.852 119.439L253.764 122.207C253.612 122.606 253.764 123.061 254.122 123.281L268.48 132.205L255.899 128.569C255.39 128.418 254.852 128.693 254.659 129.175L253.874 131.172C253.681 131.667 253.888 132.218 254.356 132.466L267.199 139.118L255.004 138.333C254.48 138.305 254.039 138.718 254.053 139.241L254.094 141.403C254.094 141.817 254.384 142.161 254.783 142.257L281.047 148.606C282.563 148.964 284.12 149.06 285.649 148.867C286.421 148.771 287.179 148.688 287.951 148.702L300.765 149.06"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M373.343 135.923L303.287 130.001C299.415 135.51 298.561 142.602 301.028 148.867L301.152 149.198C301.152 149.198 371.179 161.137 376.856 160.765C382.534 160.38 384.848 159.457 384.848 159.457L373.329 135.909H373.343V135.923Z"
            fill="white" />
          <path d="M300.793 149.06L376.87 160.765C379.901 161.233 382.947 160.614 385.51 159.113"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M373.563 135.95L303.287 130.015" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M387.632 112.443L373.342 135.923C371.606 144.447 375.244 153.164 382.52 157.929L384.862 159.471C384.862 159.471 387.494 157.997 392.138 152.214L412.972 126.256L387.632 112.443Z"
            fill="white" />
          <path d="M385.51 159.113C386.86 158.314 388.073 157.281 389.092 156.014L412.986 126.242"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M387.631 112.443L373.562 135.95" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M381.514 108.574L419.394 129.45C419.394 129.45 426.711 113.738 429.301 100.655C431.892 87.5731 425.966 77.4929 419.394 76.6391C412.821 75.7853 405.462 77.0797 395.596 87.8623C385.73 98.6449 381.514 108.574 381.514 108.574Z"
            fill="#3936DB" />
          <path
            d="M381.403 108.849L419.393 129.464C419.393 129.464 441.606 86.4026 421.543 77.424C401.48 68.4454 381.403 108.849 381.403 108.849Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M384.477 103.327L422.343 123.295" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M469.11 82.7809C469.11 82.7809 459.216 78.6634 452.258 76.9833C445.299 75.3033 439.649 73.7472 439.649 73.7472L419.049 77.3414C419.049 77.3414 405.311 86.2236 396.01 102.17C386.722 118.117 389.561 131.929 394.466 138.401C394.466 138.401 388.789 169.496 385.289 189.161C381.789 208.826 383.222 217.708 383.222 217.708L471.425 212.736L465.389 168.794L469.11 82.7809Z"
            fill="#3936DB" />
          <path
            d="M419.393 77.0798C419.393 77.0798 375.643 108.133 394.466 138.388C394.466 138.388 381.389 199.145 383.222 217.694L471.425 212.723C471.425 212.723 465.541 169.179 465.913 167.981M439.635 74.1466C439.635 74.1466 459.753 77.6857 471.425 83.9377"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M243.071 446.084L242.451 457.197C242.451 457.197 248.363 459.386 256.01 459.056C263.658 458.725 265.532 457.197 265.532 457.197L264.912 446.084H243.071Z"
            fill="white" />
          <path
            d="M242.451 457.197L241.004 483.169L296.907 485.468C296.907 485.468 296.301 482.866 290.831 480.621C285.374 478.39 274.185 475.87 269.486 468.296C264.787 460.722 265.545 457.197 265.545 457.197C265.545 457.197 257.264 461.645 242.465 457.197H242.451Z"
            fill="#3936DB" />
          <path d="M241.597 483.155L240.591 490.509H298.892L297.307 486.033L241.597 483.155Z"
                fill="#E3E3F2" />
          <path
            d="M243.071 446.084L240.591 490.509H298.892C298.892 490.509 299.154 483.843 288.433 479.533C277.713 475.223 264.25 474.3 264.898 446.084"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M241.004 483.155L297.307 486.033" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M242.451 457.197C242.451 457.197 256.878 461.383 265.532 457.197"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M154.441 446.084L152.801 458.615C152.801 458.615 158.244 460.681 164.568 459.676C170.893 458.67 175.427 457.899 175.675 457.417C175.923 456.935 175.675 446.084 175.675 446.084H154.441Z"
            fill="white" />
          <path
            d="M175.675 457.417L176.088 483.169L114.452 486.047C114.452 486.047 118.655 480.456 128.962 477.646C139.269 474.837 146.848 470.004 149.204 465.707C151.574 461.424 152.787 458.615 152.787 458.615C152.787 458.615 158.092 460.323 165.932 459.538C173.773 458.753 175.661 457.403 175.661 457.403L175.675 457.417Z"
            fill="#3936DB" />
          <path
            d="M114.976 486.253L112.868 490.812L176.447 490.274L176.075 483.155L114.976 486.253Z"
            fill="#E3E3F2" />
          <path
            d="M175.882 446.084L176.088 490.509H112.868C112.868 490.509 112.606 483.843 123.326 479.533C134.047 475.223 154.702 474.3 154.055 446.084"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M175.675 483.155L114.452 486.033" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M175.399 457.417C175.399 457.417 161.441 462.306 152.787 458.119"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M199.499 325.038L197.901 324.046C186.795 317.12 172.809 316.693 161.289 322.931L160.076 323.592L149.19 446.111H178.472L199.486 325.065V325.038H199.499Z"
            fill="white" />
          <path d="M159.47 330.464L149.204 446.084H178.486L198.232 332.392" stroke="#231F20"
                stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M213.004 190.262L237.931 339.015L238.896 340.227C246.667 349.88 259.813 353.392 271.374 348.875L260.654 189.161L212.99 190.262H213.004Z"
            fill="white" />
          <path d="M238.096 339.993L213.004 190.262" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M260.653 189.161L271.567 350.445" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M222.939 189.478L198.232 332.392L196.786 333.094C184.756 338.878 170.577 337.872 159.484 330.477L171.845 191.323L222.953 189.491L222.939 189.478Z"
            fill="white" />
          <path d="M198.232 332.391L222.939 190.042" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M171.845 191.309L159.471 330.464" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M184.769 55.3632L184.452 55.8176C177.26 66.311 174.352 79.1592 176.323 91.732L176.874 95.2436L168.041 191.323L269.224 188.968L259.978 102.735L260.626 100.408C263.754 89.2119 263.244 77.3277 259.165 66.4487L230.132 60.6787L208.994 55.0051L184.769 55.3769V55.3632Z"
            fill="#3936DB" />
          <path d="M229.801 60.9817L253.722 65.0028" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M202.104 54.7021L206.087 61.2984C212.122 71.2823 225.75 73.3479 234.473 65.5811L238.083 62.3725"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M177.039 93.4258L168.027 191.309L269.224 188.954L259.165 101.826"
                stroke="#231F20" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M208.76 54.3303L183.144 55.8038" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M288.833 94.3622L295.406 101.427L280.593 113.903L274.378 108.133L288.833 94.3622Z"
            fill="#B7B6FF" />
          <path d="M288.557 94.3622L294.716 100.766" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M274.378 108.133L280.207 113.903" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M246.86 91.4978L270.327 111.617L291.506 91.4978C291.506 91.4978 273.62 75.0692 264.167 68.7759C254.714 62.4826 233.949 75.7439 246.86 91.4978Z"
            fill="#3936DB" />
          <path
            d="M253.723 65.0027C259.234 65.9253 264.402 68.3352 268.646 71.9707L291.492 91.4978L270.768 111.617L246.847 91.4978"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M266.647 108.133L287.441 88.0138" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M324.426 114.812C324.426 114.812 326.286 115.969 328.752 115.459C331.219 114.95 337.006 113.215 338.729 114.275C340.451 115.335 342.601 116.919 342.601 116.919L333.892 138.401L321.766 128.115L320.085 127.757L324.426 114.812Z"
            fill="#B7B6FF" />
          <path
            d="M353.597 122.083L352.522 121.023L344.419 118.324L339.225 113.903L339.5 122.496L344.792 126.008L351.185 125.677L353.597 122.083Z"
            fill="white" />
          <path d="M321.118 128.115L333.547 138.112" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M324.991 115.06C326.176 115.583 327.498 115.721 328.752 115.459L335.573 114.041C337.309 113.683 339.142 114.137 340.451 115.335C343.496 118.131 349.408 120.059 352.522 121.036C353.445 121.326 353.914 122.331 353.583 123.226L353.5 123.433C352.908 125.002 351.365 126.008 349.697 125.911L344.502 125.65M323.613 120.761C323.089 122.593 323.613 123.267 323.613 123.267"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M117.167 130.401L142.673 93.5222L161.206 101.922L127.653 144.447L117.167 130.401Z"
            fill="#B7B6FF" />
          <path d="M142.673 93.5222L118.834 128.555" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M129.293 142.367L160.71 102.735" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M106.309 143.703L105.964 140.013L104.145 136.639L100.921 140.178L93.2045 143.882L92.2812 145.066L95.1198 148.33L101.5 147.848L106.364 143.744L106.309 143.703Z"
            fill="white" />
          <path
            d="M117.167 130.401L127.667 144.447L126.4 146.044C126.4 146.044 125.215 151.071 122.417 154.61C119.62 158.149 114.646 163.12 114.646 163.12L104.945 144.598L104.366 136.845C104.366 136.845 106.268 132.563 109.451 132.37C112.634 132.177 115.293 132.549 117.181 130.401H117.167Z"
            fill="white" />
          <path
            d="M118.835 128.555C117.774 130.208 116.106 131.392 114.177 131.833L108.073 133.223C106.901 133.884 105.827 134.752 105.083 135.881C102.781 139.393 96.4152 142.409 93.2046 143.882C92.3227 144.282 91.9782 145.342 92.433 146.182L92.5432 146.375C93.3286 147.848 94.9822 148.661 96.6219 148.358L101.734 147.449M114.453 163.272C114.453 163.272 124.829 154.665 126.399 146.03L129.307 142.367M106.309 143.703C110.705 143.111 114.632 140.729 114.632 140.729M122.472 135.454C123.644 137.809 124.236 139.035 124.236 139.035"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M204.613 25.0259C204.613 25.0259 201.237 16.1162 202.504 12.3843C203.772 8.66613 207.699 8.19792 207.699 8.19792C207.699 8.19792 208.223 0.293446 216.987 1.17478C225.751 2.05612 237.532 6.42148 241.969 7.17888C246.406 7.93627 247.453 5.25096 247.453 5.25096C247.453 5.25096 249.438 4.92045 249.658 6.88969C249.878 8.85892 249.658 10.0294 249.658 10.0294C249.658 10.0294 250.733 9.57501 250.926 11.9298C251.119 14.2846 250.581 18.5261 248.266 20.3576C245.951 22.1891 241.638 20.936 241.638 20.936L213.804 25.5905H211.323L204.64 25.0259H204.613Z"
            fill="#3936DB" />
          <path
            d="M211.31 25.5768C211.31 25.5768 209.973 22.6161 206.197 24.4614C202.422 26.2929 201.347 29.1021 203.056 33.0957C204.764 37.0755 210.345 37.585 210.345 37.585L208.995 54.9776C208.995 54.9776 212.55 65.5536 220.914 64.5759C229.278 63.5981 230.132 60.6512 230.132 60.6512L229.278 51.3145C229.278 51.3145 235.851 51.6864 239.296 43.6993C242.741 35.7122 240.839 18.5261 240.839 18.5261C240.839 18.5261 237.697 18.8566 229.788 17.9753C221.878 17.094 216.436 15.469 216.436 15.469C216.436 15.469 217.758 18.7327 217.166 20.8259C216.573 22.919 214.162 25.6594 214.162 25.6594L211.282 25.5906H211.31V25.5768Z"
            fill="white" />
          <path
            d="M205.949 27.601C210.193 26.4856 211.571 33.0818 211.571 33.0818M240.853 18.526C240.853 18.526 242.947 32.173 240.26 41.124C237.573 50.0751 230.063 54.0549 221.768 50.7223C213.473 47.3898 211.833 37.7226 211.833 37.7226C211.833 37.7226 203.4 37.6538 202.229 30.6031C201.057 23.5524 209.546 23.2908 211.309 25.5767L214.575 25.6456L216.256 22.8914C216.945 21.7622 217.207 20.4264 217.014 19.1182L216.408 15.1246C216.408 15.1246 234.514 19.8894 240.853 18.526Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M207.12 31.2641L209.697 28.9919" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M210.124 37.5437C210.978 43.4101 208.76 54.3166 208.76 54.3166C208.76 54.3166 211.433 62.8683 218.171 64.3142C224.909 65.7602 229.801 60.9817 229.801 60.9817C229.801 60.9817 228.699 54.9087 228.961 51.8103M229.801 32.3107C230.711 35.5744 233.783 35.8361 233.852 31.2642"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M224.579 26.1001V27.4772" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M236.54 25.6456V26.7886" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M225.75 39.0998C228.561 41.193 232.805 40.4769 232.805 40.4769M222.416 23.1669C223.587 22.1892 225.75 22.5747 225.75 22.5747M234.941 21.5832C236.457 21.3629 238.097 22.8226 238.097 22.8226M204.612 25.026C204.612 25.026 198.232 9.78162 207.699 8.21174C207.699 8.21174 207.892 -0.670464 219.66 1.54664C231.427 3.76375 244.242 10.1672 247.439 5.78807"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M249.478 6.11853C249.478 6.11853 250.65 10.6905 246.86 11.4065M250.911 11.1449C250.911 11.1449 251.146 24.3787 241.128 20.7707"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M295.406 101.427L280.593 113.903L320.099 127.757L324.425 114.812L295.406 101.427Z"
            fill="#B7B6FF" />
          <path d="M280.207 113.903L321.118 128.115" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M294.717 100.766C295.709 101.798 296.894 102.638 298.217 103.217L325.004 115.06"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M145.084 88.5509L142.673 93.5222C145.925 98.7551 151.643 101.922 157.803 101.922H161.206L162.86 99.2096L145.084 88.5509Z"
            fill="white" />
          <path
            d="M142.673 86.3063L168.951 102.735L180.057 90.1897L181.517 87.5457C186.547 78.4844 188.008 67.8809 185.624 57.8006L184.769 55.3632C184.769 55.3632 171.321 55.7763 164.307 62.524C157.293 69.2717 142.659 86.32 142.659 86.32H142.673V86.3063Z"
            fill="#3936DB" />
          <path
            d="M185.362 55.6799C176.405 56.2032 165.74 60.1968 159.525 66.6691L142.673 86.3063L168.358 102.735L180.057 90.1897"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M145.347 83.1803L171.845 99.003" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M145.346 88.0139L142.673 93.5223" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M160.71 102.735L162.86 99.2096" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M237.738 340.654L241.431 446.703H266.455L271.374 351.96L271.181 350.404L269.597 348.393C262.045 338.864 248.9 335.655 237.738 340.626V340.654Z"
            fill="white" />
          <path d="M271.567 350.445L266.648 446.084H241.624L238.097 339.993" stroke="#231F20"
                stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M332.941 93.054H113.226C109.855 93.054 107.122 95.7852 107.122 99.1544V222.831C107.122 226.2 109.855 228.931 113.226 228.931H332.941C336.312 228.931 339.045 226.2 339.045 222.831V99.1544C339.045 95.7852 336.312 93.054 332.941 93.054Z"
            fill="white" />
          <path
            d="M107.232 184.41L107.122 101.922C107.122 93.5222 111.835 93.0402 115.514 93.054H332.5C337.337 93.054 339.5 96.841 339.224 103.217L338.535 183.198L107.232 184.41Z"
            fill="white" />
          <path
            d="M107.232 184.41L107.122 206.567L339.1 206.843L339.252 183.611L262.652 183.294H253.254L107.232 184.41Z"
            fill="#8C8AE5" />
          <path d="M121.839 126.985L131.237 127.096" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M339.045 121.89H106.364V152.943H339.045V121.89Z" fill="#8C8AE5" />
          <path d="M284.685 92.3654H254.88V228.242H284.685V92.3654Z" fill="#8C8AE5" />
          <path
            d="M264.553 134.394L239.653 114.454C239.653 114.454 227.568 103.644 223.076 110.612C218.584 117.58 212.604 136.776 213.982 144.516C215.36 152.255 214.96 154.61 232.116 152.654C249.271 150.699 263.285 145.769 263.285 145.769C263.285 145.769 263.23 149.101 268.466 150.382C273.702 151.663 275.066 149.707 275.066 149.707C275.066 149.707 295.074 162.349 304.775 166.659C314.476 170.97 317.907 173.132 320.746 166.659C323.598 160.187 326.202 148.826 325.486 139.021C324.769 129.216 323.005 125.994 317.811 125.787C312.616 125.595 289.866 132.356 289.866 132.356L277.216 137.933C277.216 137.933 274.185 134.215 271.194 133.926C268.204 133.637 264.539 134.38 264.539 134.38L264.553 134.394Z"
            fill="#8C8AE5" />
          <path
            d="M332.5 93.054H113.667C110.052 93.054 107.122 95.9825 107.122 99.5951V222.39C107.122 226.002 110.052 228.931 113.667 228.931H332.5C336.115 228.931 339.045 226.002 339.045 222.39V99.5951C339.045 95.9825 336.115 93.054 332.5 93.054Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M265.6 134.67C265.6 134.67 234.224 107.362 226.384 108.285C218.543 109.207 208.208 148.661 217.234 152.186C226.259 155.711 261.99 146.044 261.99 146.044C261.99 146.044 261.287 137.286 265.6 134.683V134.67ZM265.6 134.67C265.6 134.67 273.799 133.003 276.83 137.961M274.777 149.694C274.777 149.694 309.543 172.539 317.191 170.584C324.838 168.629 329.771 128.142 320.36 125.856C310.948 123.57 276.83 137.961 276.83 137.961M274.777 149.694V149.707C278.704 146.54 276.83 137.961 276.83 137.961M274.777 149.694C267.212 153.756 261.99 146.031 261.99 146.031M264.553 135.537C264.553 141.596 276.72 146.636 276.72 146.636"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M284.685 155.918L284.299 228.945H254.88V147.821" stroke="#231F20"
                stroke-linecap="round" stroke-linejoin="round" />
          <path d="M254.88 125.774V93.054H284.299V134.904" stroke="#231F20" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M249.933 121.89H339.059V152.943H324.687" stroke="#231F20" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M279.862 152.943H107.122V121.89H217.565" stroke="#231F20" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M107.88 206.567L254.88 206.843" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M253.97 183.611L107.894 184.313" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M284.685 206.567L339.1 206.843" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M338.191 183.611L284.547 183.487" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M406.331 20.1235C406.331 20.1235 410.74 55.0188 413 60.4032C415.26 65.7876 420.827 66.3247 420.827 66.3247L439.099 62.8269C439.099 62.8269 444.693 68.5005 447.904 70.3458C451.114 72.1911 454.215 73.7472 454.215 73.7472C454.215 73.7472 468.546 67.4814 473.231 52.0305C477.916 36.5934 477.213 26.6371 477.213 26.6371C477.213 26.6371 465.542 27.5184 448.634 20.7018C431.727 13.8715 418.223 5.81554 406.345 20.1235H406.331Z"
            fill="#3936DB" />
          <path
            d="M406.331 20.1235L402.61 37.0342C402.61 37.0342 398.283 37.7365 398.27 40.408C398.256 43.0796 402.018 43.355 402.018 43.355C402.018 43.355 398.063 53.8208 406.165 58.1173C414.268 62.4138 422.728 60.1279 422.728 60.1279L419.049 77.3415C419.049 77.3415 423.031 80.6465 428.13 80.9081C433.228 81.1698 439.649 73.7473 439.649 73.7473L438.602 52.6916C438.602 52.6916 446.043 53.2975 448.014 46.4534C449.984 39.6093 446.25 35.2026 441.055 35.0649C435.86 34.9134 434.096 39.0998 434.096 39.0998C434.096 39.0998 424.024 34.7895 419.587 31.5258C415.149 28.2621 406.358 20.1235 406.358 20.1235H406.331Z"
            fill="white" />
          <path
            d="M402.031 43.2173L401.852 49.3453C401.673 55.432 406.703 60.3758 412.793 60.1141L427.276 59.4807"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M404.361 43.9196L400.213 42.6802C399.069 42.3359 398.284 41.3031 398.242 40.1188C398.201 38.7004 399.235 37.4886 400.64 37.2958L402.624 37.0341L406.317 20.4816L416.431 28.6752C419.146 30.8786 422.067 32.8065 425.168 34.4314L434.069 39.1135L435.723 36.9791C436.88 35.478 438.768 34.7206 440.656 34.9961C444.955 35.6433 448.483 39.1411 448.62 43.4789C448.799 49.3177 443.673 53.5592 438.231 52.6365L439.65 74.1328L436.04 77.851C431.382 82.6432 423.583 82.2852 419.394 77.0798"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M443.15 40.0913L435.048 46.6462" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M442.709 46.715L440.312 42.391" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M411.636 32.5448L411.374 34.0596" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M410.851 46.4672L406.331 47.6377" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path d="M422.811 59.6873L419.394 77.0799" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M438.851 62.1935C442.351 68.4455 454.215 73.7472 454.215 73.7472C479.528 61.7804 477.199 26.6509 477.199 26.6509C477.199 26.6509 459.851 26.6234 437.542 16.1713C415.233 5.7192 406.304 20.5091 406.304 20.5091C406.304 20.5091 406.69 6.35266 423.473 6.13232M412.794 60.1279C412.794 60.1279 415.591 64.3555 421.461 66.5864"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M372.089 176.533L364.318 170.308L353.473 164.208C353.473 164.208 351.957 168.091 353.818 169.661C355.678 171.231 355.912 171.975 356.339 172.484C356.766 172.994 359.825 178.213 359.825 178.213C359.825 178.213 350.455 179.067 349.036 178.846C347.617 178.626 334.871 174.743 334.871 174.743L333.341 178.24C333.341 178.24 342.959 183.046 342.849 183.005C342.739 182.964 327.926 182.537 327.926 182.537L328.091 186.379L342.849 190.083L330.599 191.75C330.599 191.75 331.095 195.358 331.095 195.275C331.095 195.192 344.434 196.996 344.434 196.996L333.603 201.334L335.491 204.281C335.491 204.281 363.78 200.852 366.04 199.916C368.3 198.979 371.469 197.327 373.398 196.624C375.327 195.922 381.542 193.829 381.542 193.829L381.831 191.612C382.617 185.622 380.66 179.59 376.512 175.183L372.103 176.505V176.533H372.089Z"
            fill="white" />
          <path
            d="M376.498 175.211L371.538 176.698C371.538 176.698 367.252 172.099 364.042 170.322C361.368 168.849 355.705 165.392 353.859 164.263C353.569 164.084 353.183 164.263 353.128 164.594C352.88 166.053 352.536 169.4 354.396 170.639C356.807 172.25 360.349 178.185 360.349 178.185L351.778 178.805C350.042 178.929 348.305 178.736 346.652 178.227L335.311 174.784C334.76 174.619 334.181 174.908 333.989 175.459L333.313 177.414C333.134 177.937 333.382 178.516 333.892 178.736L344.296 183.322L328.532 182.11C328.022 182.069 327.581 182.482 327.581 182.991V185.952C327.581 186.379 327.884 186.751 328.311 186.82L344.943 189.891L331.894 191.089C331.37 191.144 330.957 191.584 330.957 192.108V194.256C330.957 194.779 331.357 195.22 331.88 195.275L346.266 196.79L334.622 200.508C334.126 200.673 333.865 201.224 334.071 201.706L334.898 203.703C335.05 204.075 335.449 204.309 335.849 204.254L362.622 200.591C364.166 200.384 365.64 199.888 367.004 199.158C367.679 198.787 368.368 198.429 369.099 198.167L381.17 193.829"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M443.88 155.174L376.498 175.211C374.9 181.752 376.705 188.665 381.293 193.609L381.528 193.87C381.528 193.87 451.1 179.466 456.24 177.042C461.38 174.619 463.213 172.911 463.213 172.911L443.894 155.188H443.88V155.174Z"
            fill="white" />
          <path d="M381.128 193.857L456.226 177.029C459.217 176.354 461.835 174.674 463.667 172.333"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M444.1 155.119L376.498 175.211" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M448.62 128.115L443.88 155.188C445.382 163.753 451.941 170.543 460.457 172.333L463.199 172.911C463.199 172.911 465.114 170.584 467.333 163.506L477.254 131.736L448.62 128.128V128.115Z"
            fill="white" />
          <path d="M463.681 172.333C464.645 171.107 465.403 169.689 465.885 168.146L477.267 131.722"
                stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M448.62 128.115L444.101 155.119" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M441.51 126.738L484.405 132.37C484.405 132.37 485.48 115.074 483.11 101.95C480.74 88.8264 471.563 81.5967 465.114 83.2079C458.665 84.819 452.299 88.6887 447.063 102.336C441.813 115.969 441.51 126.751 441.51 126.751V126.738Z"
            fill="#3936DB" />
          <path
            d="M441.51 127.027L484.405 132.37C484.405 132.37 489.38 84.1718 467.415 83.139C445.451 82.1062 441.51 127.04 441.51 127.04V127.027Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M442.351 120.761L484.888 125.553" stroke="#1F1F23" stroke-linecap="round"
                stroke-linejoin="round" />
          <path
            d="M336.674 126.063L333.105 127.784L334.911 148.413L340.85 146.912L345.645 143.979L344.791 126.008L339.499 122.496L336.674 126.063Z"
            fill="white" />
          <path
            d="M334.194 145.342C334.194 145.342 341.098 144.35 345.383 139.035M333.891 140.729C333.891 140.729 339.871 139.792 345.094 134.573M333.547 135.331C333.547 135.331 341.291 133.045 344.68 128.101M345.686 143.428L343.619 145.135C341.056 147.242 337.86 148.399 334.539 148.427H334.401L333.078 128.252C333.078 128.252 339.665 124.961 339.513 122.496L344.474 124.878L345.7 143.428H345.686Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M113.323 148.151L109.561 146.884L106.364 143.744L101.5 147.848L102.919 165.792L108.045 168.091L114.122 168.835L113.323 148.151Z"
            fill="white" />
          <path
            d="M114.453 165.695C114.453 165.695 107.481 165.571 102.561 160.848M114.164 161.082C114.164 161.082 108.115 160.903 102.272 156.386M113.847 155.684C113.847 155.684 105.882 154.389 101.9 149.914M102.823 165.241L105.083 166.673C107.88 168.436 111.215 169.193 114.508 168.794H114.646L113.42 148.606C113.42 148.606 106.461 146.168 106.323 143.703L101.707 146.691L102.837 165.255H102.823V165.241Z"
            stroke="#1F1F23" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_8183_2082">
            <rect width="574" height="491" fill="white" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </main>
</template>

<style scoped>
main {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.left-column {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 40rem;
  padding: 4rem 8rem;
  border-radius: 0 2.5rem 2.5rem 0;
  background-color: #fff;
}

#partner-logo {
  height: 3.6rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.right-column {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.welcome-art {
  width: 25rem;
}
</style>

<style>
.external-form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.external-heading {
  color: var(--grey-300);
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}

.external-input-fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.external-button-group {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.external-button-error-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.incorrect-text {
  height: 1.5rem;
  color: var(--danger-500);
}
</style>
